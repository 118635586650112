@use '/node_modules/@angular/material' as mat;
@use '/node_modules/@angular/material/theming';
@use "sass:map";
@include mat.core();
@include mat.legacy-core();
@import 'variables.scss';

.orange-theme {

  $dh-app-primary: mat.define-palette($mat-dhorange, 500, 300, 700);
  $dh-app-accent: mat.define-palette($mat-dhblue, 500, 300, 700);
  $dh-app-theme: mat.define-light-theme(( color: ( primary: $dh-app-primary, accent: $dh-app-accent, ), typography: mat.define-typography-config(), density: 0, ));

  --theme-primary-color: #ea7931;
  --theme-accent-color: #59b8e8;
  --theme-tab-active-color: #fae3d4;
  --theme-background-color: white;
  --theme-font-color: black;
  --theme-auto-complete-bgcolor: #e6e6e6;
  --theme-valid-color: #42A948;
  --theme-datepicker-bgcolor: #dcdcdc;
  --theme-mat-select-disabled-color: rgba(0, 0, 0, .87);
  --theme-popup-close-bgcolor: #E8E8E8;
  --theme-popop-save-button-font-color: white;
  --theme-updated-email-message-color: #64605f;
  --theme-anchor-link-color: #59b8e8;
  --theme-card-bg-color-: #FAF7F6;
  --theme-email-hint-message: #64605f;
  --theme-warn-btn-bgcolor: #C1453C;
  --theme-warn-btn-color: #fff;
  --theme-raised-button-color: #fff;
  --theme-mat-header-cell: #ffffff;
  --theme-warn-btn-color: #fff;
  --theme-mat-chip-bgcolor: #e0e0e0;
  --theme-verfication-code-color: #ffffff;
  --theme-verfication-code-disabledcolor: #e2e3e5;
  --theme-stroked-button-color: #ffffff;
  --theme-divider-color: #A8A8A8;
  --theme-email-hint-message-color: #64605f;
  --theme-bargroup-facilities-ul-li-hover-color: #f5f5f5;
  --theme-mat-tab-bottom-color: rgba(0, 0, 0, 0.12);
  background-color: white;
  color: black;

  .mat-header-row {
    background-color: var(--theme-primary-color) !important;

    .mat-header-cell {
      color: white !important;
    }
  }

  @include mat.all-component-themes($dh-app-theme);
  @include mat.all-legacy-component-themes($dh-app-theme);
}

.blue-theme {

  $dh-app-primary: mat.define-palette($mat-dhblue, 500, 300, 700);
  $dh-app-accent: mat.define-palette($mat-dhblue, 500, 300, 700);
  $dh-app-theme: mat.define-dark-theme(( color: ( primary: $dh-app-primary, accent: $dh-app-accent, ), typography: mat.define-typography-config(), density: 0, ));

  --theme-primary-color: #59B8E8;
  --theme-accent-color: #59b8e8;
  --theme-tab-active-color: #59B8E8;
  --theme-background-color: #484444;
  --theme-font-color: white;
  --theme-auto-complete-bgcolor: #e6e6e6;
  --theme-valid-color: #42A948;
  --theme-datepicker-bgcolor: #dcdcdc;
  --theme-mat-select-disabled-color: rgba(0, 0, 0, .87);
  --theme-popup-close-bgcolor: #484444;
  --theme-popop-save-button-font-color: white;
  --theme-updated-email-message-color: #64605f;
  --theme-anchor-link-color: #59b8e8;
  --theme-card-bg-color-: #FAF7F6;
  --theme-email-hint-message: #64605f;
  --theme-warn-btn-bgcolor: #C1453C;
  --theme-warn-btn-color: #fff;
  --theme-raised-button-color: #fff;
  --theme-mat-header-cell: #ffffff;
  --theme-warn-btn-color: #fff;
  --theme-mat-chip-bgcolor: #e0e0e0;
  --theme-verfication-code-color: #ffffff;
  --theme-verfication-code-disabledcolor: #e2e3e5;
  --theme-stroked-button-color: #ffffff;
  --theme-mat-tab-bottom-color: #484444;
  background-color: black;
  color: white;

  .mat-header-row {
    background-color: var(--theme-primary-color) !important;

    .mat-header-cell {
      color: white !important;
    }
  }

  .mat-option {
    color: black !important;
  }

  @include mat.all-component-themes($dh-app-theme);
  @include mat.all-legacy-component-themes($dh-app-theme);
}

.grey-theme {

  $dh-app-primary: mat.define-palette(mat.$grey-palette, 500, 300, 700);
  $dh-app-accent: mat.define-palette(mat.$grey-palette, 500, 300, 700);
  $dh-app-theme: mat.define-light-theme(( color: ( primary: $dh-app-primary, accent: $dh-app-accent, ), typography: mat.define-typography-config(), density: 0, ));

  --theme-primary-color: #9E9E9E;
  --theme-accent-color: #59b8e8;
  --theme-tab-active-color: #DFDFDF;
  --theme-background-color: white;
  --theme-font-color: black;
  --theme-auto-complete-bgcolor: #e6e6e6;
  --theme-valid-color: #42A948;
  --theme-datepicker-bgcolor: #dcdcdc;
  --theme-mat-select-disabled-color: rgba(0, 0, 0, .87);
  --theme-popup-close-bgcolor: #E8E8E8;
  --theme-popop-save-button-font-color: white;
  --theme-updated-email-message-color: #64605f;
  --theme-anchor-link-color: #59b8e8;
  --theme-card-bg-color-: #FAF7F6;
  --theme-email-hint-message: #64605f;
  --theme-warn-btn-bgcolor: #C1453C;
  --theme-warn-btn-color: #fff;
  --theme-raised-button-color: #fff;
  --theme-mat-header-cell: #ffffff;
  --theme-warn-btn-color: #fff;
  --theme-mat-chip-bgcolor: #e0e0e0;
  --theme-verfication-code-color: #ffffff;
  --theme-verfication-code-disabledcolor: #e2e3e5;
  --theme-stroked-button-color: #ffffff;
  --theme-mat-tab-bottom-color: rgba(0, 0, 0, 0.12);
  background-color: white;
  color: black;

  .mat-header-row {
    background-color: var(--theme-primary-color) !important;

    .mat-header-cell {
      color: black !important;
    }
  }

  @include mat.all-component-themes($dh-app-theme);
  @include mat.all-legacy-component-themes($dh-app-theme);
}
