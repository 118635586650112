/* You can add global styles to this file, and also import other style files */
@use '/node_modules/@angular/material' as mat;
@import "/node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "app.themes.scss";

body {
  margin: 0;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

.flex-container {
  display: flex;
  justify-content: center;

  > *:not(router-outlet) {
    flex: 0 1 640px;
  }
}

.page-content {
  padding: 0 2em;
  margin: 1% 10% 1% 10%;
}

.mat-header-row {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: inherit;
}

.mat-card-content,
.mat-card-header .mat-card-title,
.mat-expansion-panel-content {
  font-size: 16px;
}

.mat-panel-title {
  display: inline-block;
  vertical-align: middle;
}

.mat-form-field.app-validate.ng-valid {

  .mat-form-field-label {
    color: var(--theme-valid-color);
  }

  .mat-form-field-underline {
    background-color: var(--theme-valid-color);
  }
}

.mat-icon-margin-left {
  margin-left: -7%;
}

.mat-button-icon {
  margin-right: 16px;
}

.mat-icon.mat-icon-lg {
  width: 48px;
  height: 48px;
  font-size: 48px;
}

.mat-icon.mat-icon-sg {
  width: 30px;
  height: 30px;
  font-size: 30px;
}

.mat-icon.mat-icon-mg {
  width: 45px;
  height: 45px;
  font-size: 45px;
}

.vertical-center {
  display: inline-block;
  vertical-align: middle;
}

.mat-tab-label-active {
  background-color: var(--theme-tab-active-color) !important;
  opacity: 1 !important;
}

.icon-hover {
  cursor: pointer;
}

.popup-spinner {
  left: 40%;
}

.mat-cell,
.mat-header-cell {
  margin-right: 1em;
}
/*Date Range Calendar Theming*/
ngx-daterangepicker-material {
  position: absolute;
  width: 600px;
  left: 1%;
  margin-top: 17px;
}

.md-drppicker td.active,
.md-drppicker .btn {
  background-color: mat-color(--theme-primary-color, 500) !important;
}

.md-drppicker .btn.btn-default {
  background-color: var(--theme-datepicker-bgcolor) !important;
}

.md-drppicker .btn.btn-default.clear {
  background: none !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--theme-primary-color) !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: var(--theme-tab-active-color) !important;
}

.mat-slide-toggle-bar {
  width: 55px !important;
  height: 25px !important;
}

.mat-slide-toggle-thumb {
  height: 23px !important;
  width: 23px !important;
}

.mat-slide-toggle-thumb-container {
  top: 0.25px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(31px, 0, 0) !important;
}

.mat-slide-toggle-bar {
  border-radius: 12px !important;
}

.mat-input-element:disabled,
.mat-select-disabled .mat-select-value {
  color: var(--theme-mat-select-disabled-color) !important;
}

.mat-select-panel {
  background: #fff;
}

.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, .12);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--theme-primary-color) !important;
}

.mat-card-password {
  padding: 5px !important;
  text-align: center;
  margin-top: 10px;
}

.popup-save {
  background-color: var(--theme-primary-color) !important;
  color: var(--theme-popop-save-button-font-color) !important;
  width: 85px;
}

.popup-close {
  background-color: var(--theme-popup-close-bgcolor) !important;
  width: 85px;
}

.mat-header-cell {
  font-size: 14px;
}

.mat-tab-label {
  height: 38px !important;
}

.mat-tab-header {
  border-bottom: 4px solid var(--theme-mat-tab-bottom-color) !important;
}

.mat-ink-bar {
  border-bottom: 4px solid var(--theme-primary-color) !important;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: rgba(97,97,97,.9)
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px
}
